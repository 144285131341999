<template>
  <ClientOnly>
    <v-row justify="center" class="mt-4 mt-md-9 mb-2">
      <v-col cols="12" sm="8" xl="7" xxl="5" class="px-0 py-sm-16 py-md-3">
        <v-icon class="position-absolute mt-7 animated-arrow">custom:polygon</v-icon>
        <form class="rounded-pill bg-white overflow-hidden custom-shadow-heavy-bottom">
          <v-row no-gutters>
            <v-col cols="12" md="4" class="px-8 px-md-10">
              <v-menu
                id="menu-zipcode-hero"
                v-model="zipDropdown"
                transition="slide-y-transition"
                role="combobox"
                content-class="plz-menu">
                <template #activator="{ props }">
                  <HeroTextfield
                    v-model="postalCodeString"
                    :placeholder="smAndDown ? t('enter_zip_short') : t('enter_zipcode')"
                    class="toolbar"
                    v-bind="props"
                    @focus="resetPayloadZipcode"
                    @input="checkZipInput" />
                </template>
                <v-list v-if="cities.length > 0">
                  <v-list-item v-for="item in cities" :key="item?.zip" @click="selectZipcode(item)">
                    <v-list-item-title>
                      {{ `${item?.zip}, ${item?.city}` }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="12" md="4" class="pl-6 pl-md-2 pr-3">
              <ClientOnly>
                <GlobalWasteDropdown type="hero" @selected-waste="setPath" />
              </ClientOnly>
            </v-col>
            <v-col cols="12" md="4" class="pa-4 pt-0 pa-md-0">
              <v-tooltip
                top
                :disabled="!wrongZipcode && path !== null && path !== ''"
                open-on-click>
                <template #activator="{ props }">
                  <div v-bind="props">
                    <v-btn
                      color="primary"
                      class="rounded-pill toolbar-button px-0 elevation-0"
                      width="100%"
                      height="78px"
                      @click="goToPage()">
                      {{ t('show_container') }}
                    </v-btn>
                  </div>
                </template>
                <span v-if="wrongZipcode">{{ t('enter_valid_zip') }}</span>
                <span v-if="!wrongZipcode && (path === null || path === '')">
                  {{ t('please_choose_waste_type') }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
  </ClientOnly>
</template>
<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type { City, CityApiResponse, Fraction } from '~/types/types'

const offerStore = useOfferStore()
const { currentCity } = storeToRefs(offerStore)

const { smAndDown } = useDisplay()
const { t } = useI18n()

const wrongZipcode = ref(true)
const postalCodeString = ref('')
const postalCode = ref('')
const community = ref<number | null>(null)
const cities = ref<CityApiResponse[]>([])
const city = ref('')
const path = ref('')
const { checkZipcode } = useApiWrapper()
const zipDropdown = ref(false)

const rules = {
  required: (value: string) => !!value || 'Field is required',
  zip: (value: string) =>
    (value.length === 5 && [...value].every(item => !isNaN(parseInt(item, 10)))) ||
    'Postal code must be 5 digits',
}

const setPath = (waste: Fraction) => {
  offerStore.setCurrentFractionId(waste.id)
  path.value = waste.shop_slug
}

const goToPage = async () => {
  if (rules.zip(postalCode.value) && wrongZipcode.value === false && path.value !== '') {
    offerStore.setCurrentCity({
      zip: postalCode.value,
      name: city.value,
      community: community.value as number,
    })
    return await navigateTo('/' + path.value)
  }
  return
}
const checkZipInput = () => {
  cities.value = []
  if (postalCodeString.value.length < 3 || postalCodeString.value.length > 5) {
    return
  }
  checkZipcode(postalCodeString.value).then((response: any) => {
    if (!response || response === null) {
      return
    } else {
      cities.value = response as CityApiResponse[]
      if (cities.value.length < 1) {
        wrongZipcode.value = true
      } else if (cities.value.length === 1 && postalCode.value !== cities.value[0]?.zip) {
        selectZipcode(cities.value[0])
        zipDropdown.value = false
      } else {
        zipDropdown.value = true
      }
    }
  })
}
const selectZipcode = (response: CityApiResponse) => {
  wrongZipcode.value = false
  postalCode.value = response?.zip as string
  city.value = response?.city as string
  community.value = response?.community as number
  postalCodeString.value = `${response?.zip}, ${response?.city}`
}
const resetPayloadZipcode = () => {
  postalCode.value = ''
  city.value = ''
  community.value = null
  postalCodeString.value = ''
  wrongZipcode.value = true
}

onNuxtReady(() => {
  const previousSelectedCity = currentCity.value as City
  if (previousSelectedCity?.zip) {
    cities.value = [
      {
        zip: previousSelectedCity.zip,
        city: previousSelectedCity.name,
        community: previousSelectedCity.community,
      },
    ]
    postalCode.value = previousSelectedCity.zip
    city.value = previousSelectedCity.name
    community.value = previousSelectedCity.community
    postalCodeString.value = `${previousSelectedCity.zip}, ${previousSelectedCity.name}`
    wrongZipcode.value = false
  }
})
</script>
<style lang="scss" scoped>
form {
  @media (max-width: 959px) {
    border-top-right-radius: 24px !important;
    border-top-left-radius: 24px !important;
    border-bottom-right-radius: 54px !important;
    border-bottom-left-radius: 54px !important;
  }
}
.animated-arrow {
  margin-left: -10px;
  animation: slideRightBack 8s infinite;
}

@keyframes slideRightBack {
  0% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  55% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}
.toolbar {
  min-height: 78px;
}
.toolbar-button {
  @media (min-width: 960px) {
    border-radius: 0 !important;
  }
}
.toolbar-button:deep(span) {
  white-space: wrap !important;
  font-weight: 700;
  font-size: 1.1rem;
}
.toolbar:deep(input::placeholder) {
  color: black;
  opacity: 1;
  font-weight: 700;
  font-size: 1.1rem;
}
.toolbar:deep(input:focus::placeholder) {
  opacity: 0.2;
}
.toolbar:deep(input) {
  @media (max-width: 599px) {
    border-bottom: 1px solid #eee;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  @media (min-width: 600px) {
    margin-top: 15px;
    margin-bottom: -15px;
    padding-top: 5px;
    border-right: 1px solid #eee;
  }
}
.toolbar:deep(.v-input__details) {
  display: none;
}
@media (max-width: 599px) {
  .toolbar:deep(input::placeholder) {
    font-size: 14px;
  }
  .toolbar-button:deep(span) {
    white-space: wrap !important;
    font-weight: 700;
    font-size: 14px;
  }
}
</style>
